<template>
  <div>
    <v-container fluid fill-height>
      <v-row class="align-self-center my-6 formCenter">
        <v-col>
          <v-card flat class="text-center pa-2">
            <c-page-heading
              :heading="$t('account.register.complete')"
              :subHeading="$t('account.register.registration')"
            ></c-page-heading>

            <v-container>
              <v-row class="justify-center">
                <v-col cols="12" :sm="8" :md="6" class="my-3 text-center">
                  <div class="login-card">
                    <div class="legacy-input-title">
                      <h3
                        class="font-weight-light"
                        data-cy="completeYourProfileAndRegisterToGetStarted"
                      >
                        {{ $t('account.register.completeYourProfileAndRegisterToGetStarted') }}
                      </h3>
                    </div>
                    <div>
                      <input
                        v-model="values.givenName"
                        type="text"
                        class="legacy-input"
                        placeholder="First name"
                        data-cy="givenName-input"
                      />
                      <p class="error--text mb-2" v-if="errors.givenName">
                        <small>{{ errors.givenName }} </small>
                      </p>
                    </div>
                    <div>
                      <input
                        @keyup.enter="onSubmit"
                        v-model="values.surname"
                        type="text"
                        class="legacy-input"
                        placeholder="Last name"
                        data-cy="surname-input"
                      />
                      <p class="error--text mb-2" v-if="errors.surname">
                        <small>{{ errors.surname }} </small>
                      </p>
                    </div>
                    <div>
                      <v-checkbox
                        class="cCheckbox"
                        hide-details
                        v-model="values.ageConfirmed"
                        data-cy="ageConfirmed-checkbox"
                      >
                        <template v-slot:label>
                          <small>
                            <span class="grey--text text--darken-1">
                              {{ $t('account.register.iConfirmIAmOver_18') }}.
                            </span>
                          </small>
                        </template>
                      </v-checkbox>
                      <p class="error--text my-2" v-if="errors.ageConfirmed">
                        <small>{{ $t('account.register.pleaseConfirmThatYouAreOver18') }}</small>
                      </p>
                    </div>

                    <v-btn
                      type="submit"
                      x-large
                      @click.prevent="onSubmit"
                      :disabled="isSubmitting"
                      color="primary"
                      rounded
                      class="submit-btn-text submit-btn mt-4"
                      data-cy="button"
                    >
                      {{ $t('account.register.complete') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      isSubmitting: false,
      values: {
        givenName: '',
        surname: '',
        ageConfirmed: false,
      },
      errors: {
        givenName: '',
        surname: '',
        ageConfirmed: '',
      },
    }
  },
  methods: {
    onSubmit() {
      if (this.isSubmitting || this.validate() !== true) {
        return
      }
      this.isSubmitting = true

      this.$store.dispatch('auth/register', this.values).then(
        () => {
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          this.$router.push({ path: '/' })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    validate() {
      this.errors = {
        givenName: '',
        surname: '',
        ageConfirmed: '',
      }
      let valid = true
      if (this.values.givenName.length < 2 || this.values.givenName.length > 100) {
        this.errors.givenName = this.$t('account.register.error')
        valid = false
      }
      if (this.values.surname.length < 2 || this.values.surname.length > 100) {
        this.errors.surname = this.$t('account.register.error')
        valid = false
      }
      if (this.values.ageConfirmed !== true) {
        this.errors.ageConfirmed = this.$t('account.register.pleaseConfirmThatYouAreOver18')
        valid = false
      }
      this.isSubmitting = false
      return valid
    },
  },
}
</script>
<style scoped lang="scss">
.cCheckbox {
  text-align: center;
  ::v-deep {
    .v-input__slot {
      justify-content: center;
      .v-label {
        flex: none;
      }
    }
  }
}
::v-deep .v-input--selection-controls {
  margin-top: 2px;
}
</style>
